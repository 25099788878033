import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as service from "../utils/Services";
import { addItemToCart, clearItemFromCart, removeItemFromCart } from "./Utils";

export const informationQuery = () =>
  useQuery({
    queryKey: ["informationQuery"],
    queryFn: service.fetchCompanyInfo,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["crsfTokenQuery"] });
    },
  });
export const aboutQuery = () =>
  useQuery({
    queryKey: ["aboutQuery"],
    queryFn: service.fetchInfo,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["crsfTokenQuery"] });
    },
  });

export const newsQuery = () =>
  useQuery({
    queryKey: ["newsQuery"],
    queryFn: service.fetchNews,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["crsfTokenQuery"] });
    },
  });

export const pasterysQuery = () =>
  useQuery({
    queryKey: ["pasterysQuery"],
    queryFn: service.fetchPasterys,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["crsfTokenQuery"] });
    },
  });
export const pasterysFavouritesQuery = () =>
  useQuery({
    queryKey: ["pasterysFavouritesQuery"],
    queryFn: service.fetchFavouritesPasterys,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["crsfTokenQuery"] });
    },
  });

export const cartItemsQuery = () =>
  useQuery({
    queryKey: ["cartItemsQuery"],
    queryFn: service.getCartItems,
  });

export const crsfTokenQuery = () =>
  useQuery({
    queryKey: ["crsfTokenQuery"],
    queryFn: service.fetchCSRFToken,
  });

export const addItemToCartMutation = (item) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addItemToCart,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cartItemsQuery"] });
    },
  });
};

export const removeItemToCartMutation = (index) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: removeItemFromCart,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cartItemsQuery"] });
    },
  });
};

export const clearCartItemsQuery = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: clearItemFromCart,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cartItemsQuery"] });
    },
  });
};
