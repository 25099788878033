import { Badge, IconButton } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { cartItemsQuery } from "../../utils/Querys";

const ShoppingCartButton = ({ handleClick }) => {
  const shoppingCartList = cartItemsQuery();
  return (
    <IconButton onClick={handleClick}>
      <Badge
        badgeContent={
          shoppingCartList.isFetched && shoppingCartList.data.length
        }
        color="primary"
      >
        <ShoppingCartIcon />
      </Badge>
    </IconButton>
  );
};

export default ShoppingCartButton;
