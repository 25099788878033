import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from "@mui/material";
import * as helper from "../../utils/Utils";
import React from "react";
import { removeItemToCartMutation } from "../../utils/Querys";

const ShippingCartPastery = ({ pastery, index, disabled }) => {
  const deleteQuery = removeItemToCartMutation();

  return (
    <Card
      sx={{
        display: "flex",
        maxWidth: "380px",
        m: "4px",
        height: 130,
        width: "95%",
        overflow: "clip",
      }}
    >
      <CardMedia
        component="img"
        src={pastery.image}
        sx={{ width: 135, fillObject: "cover" }}
        alt={pastery.name}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          whiteSpace: "nowrap",
        }}
      >
        <CardHeader
          title={pastery.name + " "}
          titleTypographyProps={{ fontSize: 18, textAlign: "center" }}
          subheader={
            pastery.allergens.length > 0 &&
            helper.convertAllergensOfPasteryToStringOfAbbrevations(
              pastery.allergens
            )
          }
          sx={{ whiteSpace: "normal", p: 0, m: "auto" }}
          disableTypography
        />
        <CardContent
          sx={{ display: "flex", justifyContent: "space-around", pt: 0 }}
        >
          <Typography sx={{ margin: "auto" }} variant="body1">
            {pastery.price.size.forThisMany} Hengen
          </Typography>
          <Typography sx={{ margin: "auto" }} variant="h6">
            {Number(pastery.price.price)}€
          </Typography>
        </CardContent>
        <CardActionArea sx={{ width: "100%", textAlign: "center" }}>
          <Button
            variant="contained"
            color="error"
            disabled={disabled}
            sx={{ mb: 1, width: "95%" }}
            onClick={() => {
              deleteQuery.mutate(index);
            }}
          >
            Poista korista
          </Button>
        </CardActionArea>
      </Box>
    </Card>
  );
};

export default ShippingCartPastery;
