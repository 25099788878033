import { createTheme } from "@mui/material";

export const mainTheme = createTheme({
  palette: {
    mode: "light",
    secondary: {
      main: "#FFEEC3",
    },
    primary: {
      main: "#454b86",
    },
  },
  typography: {
    h1: {
      fontFamily: "Indie Flower",
      fontSize: "50px",
    },
    h2: {
      fontFamily: "Indie Flower",
      fontSize: "35px",
    },
    body2: {
      fontFamily: "Shantell Sans",
      fontSize: "18px",
    },
  },
});
