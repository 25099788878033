//** Conains all the helper functions and variables of this project */

import dayjs from "dayjs";

export let cartHook = [];
export let customerInfo = {};

/**
 * Lists all categorys based on pasterys.
 * @param {*} pasterys The fetched list of pasterys
 * @returns An array with unique in use categorys
 */
export const listAllCategorysWithPasterys = (pasterys) =>
  Array.from(new Set(pasterys.map((pastery) => pastery.category.name)));

/**
 * Converts the allergens into a string of the abbreviations of them
 * @param {*} allergens list of allergens
 * @returns string with abbreviations of the allergens
 */
export const convertAllergensOfPasteryToStringOfAbbrevations = (allergens) =>
  `(${allergens.map((allergen) => allergen.abbrevation).join(", ")})`;

/**
 * Parses fetched company info data
 * @param {*} obj fetched data
 * @returns returns an object with parsed data
 */
export const parseCompanyInformation = (obj) => {
  delete obj.id;
  return obj;
};

const parsePasteryForCart = (pastery) => {
  const toParse = ["name", "image", "price_set", "allergens", "price"];
  const pasteryCopy = { ...pastery };
  const parsedPastery = {};

  toParse.forEach((element) => {
    if (element === "price") {
      parsedPastery[element] = pasteryCopy[element];
    } else parsedPastery[element] = pasteryCopy[element];
  });

  return parsedPastery;
};

/**
 * adds pastery to cart
 * @param {} item
 */
export const addItemToCart = (item, price) => {
  new Promise((resolve, reject) => {
    cartHook = [parsePasteryForCart(item), ...cartHook];
    resolve();
  });
};

/**
 * adds pastery to cart
 * @param {} item
 */
export const removeItemFromCart = (index) => {
  new Promise((resolve, reject) => {
    cartHook.splice(index, 1);
    resolve();
  });
};

/**
 * clears cart from items
 */
export const clearItemFromCart = () => {
  new Promise((resolve, reject) => {
    cartHook = [];
    resolve();
  });
};

export const parsePasterysForOrder = (pasterys) =>
  pasterys.map((pastery) => {
    const allergens = pastery.allergens.map((allergen) => allergen.abbrevation);
    const parsedPastery = {
      name: pastery.name,
      price: pastery.price.price,
      priceFormat: `${pastery.price.size.forThisMany} ${pastery.price.size.name}`,
      allergens: allergens.join(", "),
    };
    return parsedPastery;
  });
