import React from "react";
import { useMediaQuery } from "@mui/material";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import { useQuery } from "@tanstack/react-query";
import * as service from "../../utils/Services";
import { newsQuery } from "../../utils/Querys";

const News = () => {
  const [emblaRef] = useEmblaCarousel(
    {
      loop: true,
      slidesToScroll: 1,
      align: "start",
      containScroll: "trimSnaps",
    },
    [Autoplay({ delay: 8000 })]
  );
  const phoneView = useMediaQuery("(max-width:600px)");
  const tabletView = useMediaQuery("(max-width:960px)");
  const maxHeight = phoneView && tabletView ? "350px" : "500px";
  const newsQueryVar = newsQuery();

  if (newsQueryVar.isLoading) {
    return <div>Loading...</div>;
  }

  if (newsQueryVar.isError) {
    return <div>Error fetching data</div>;
  }

  const embla = {
    overflow: "hidden",
    maxHeight: maxHeight,
  };

  const emblaContainer = {
    display: "flex",
  };

  return (
    <>
      {newsQueryVar.data && newsQueryVar.data.length > 0 ? (
        <div style={embla} ref={emblaRef}>
          {!phoneView || !tabletView ? (
            <div style={emblaContainer}>
              {newsQueryVar.data.map((topic) => {
                return (
                  <div
                    key={topic.id}
                    style={{
                      color: "white",
                      fontSize: "30px",
                      textAlign: "center",
                      letterSpacing: "5px",
                      fontFamily: "indie flower",
                      fontWeight: "bold",
                      flex: phoneView || tabletView ? "0 0 100%" : "0 0 50%",
                      minWidth: 0,
                      maxHeight: maxHeight,
                      height: maxHeight,
                      backgroundImage: `url(${topic.image})`,
                      backgroundSize: "cover",
                      alignItems: "center",
                      position: "relative",
                      backgroundPosition: "center center",
                    }}
                  >
                    <div
                      style={{
                        color: "white",
                        fontSize: "30px",
                        textAlign: "center",
                        letterSpacing: "5px",
                        fontFamily: "indie flower",
                        fontWeight: "bold",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        position: "absolute",
                      }}
                    >
                      {topic.title}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div style={emblaContainer}>
              {newsQueryVar.data.map((topic) => {
                return (
                  <div
                    style={{
                      color: "white",
                      fontSize: "30px",
                      textAlign: "center",
                      letterSpacing: "5px",
                      fontFamily: "indie flower",
                      fontWeight: "bold",
                      flex: phoneView ? "0 0 100%" : "0 0 50%",
                      minWidth: 0,
                      maxHeight: maxHeight,
                      height: maxHeight,
                      backgroundImage: `url(${topic.image})`,
                      backgroundSize: "cover",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        color: "white",
                        fontSize: "30px",
                        textAlign: "center",
                        letterSpacing: "5px",
                        fontFamily: "indie flower",
                        fontWeight: "bold",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        position: "absolute",
                      }}
                    >
                      {topic.title}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default News;
