import { Box, Typography } from "@mui/material";
import * as helper from "../../utils/Utils";
import React from "react";

const ContactInfo = ({ information }) => {
  const parsedInfo = helper.parseCompanyInformation(information);
  const info = [
    "Puhelinnumero",
    "Sähköpostiosoite",
    "Osoite",
    "Postinumero",
    "Kaupunki",
  ];

  return (
    <Box sx={{ width: "100%", maxWidth: 450 }}>
      {Object.entries(parsedInfo).map(([key, value], i) => (
        <Typography key={key} variant="body2" sx={{ m: "1rem" }}>
          {value}
        </Typography>
      ))}
    </Box>
  );
};

export default ContactInfo;
