import { Box, Divider, Typography } from "@mui/material";
import React, { forwardRef } from "react";
import ItemCardPhone from "./ItemCardPhone";

const ItemSectionPhone = forwardRef(({ category, pasterys }, ref) => {
  const backgroundStyle = {
    backgroundColor: "#FFEEC3",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  };

  return (
    <Box sx={backgroundStyle} ref={ref}>
      <Typography variant="h1">{category}</Typography>
      {pasterys.map((pastery, i) => (
        <ItemCardPhone pastery={pastery} key={pastery.id} />
      ))}
      <Divider variant="middle" sx={{ margin: 3 }} />
    </Box>
  );
});

export default ItemSectionPhone;
