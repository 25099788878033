import React, { forwardRef } from "react";
import ItemCard from "./ItemCard";
import { Box, Divider, Typography } from "@mui/material";

const ItemSection = forwardRef(({ category, pasterys, row = false }, ref) => {
  const backgroundStyle = {
    backgroundColor: "#FFEEC3",
    textAlign: "center",
    margin: "1rem",
    backgroundColor: "wheat",
    borderRadius: "5px",
    boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.75)",
  };

  const itemCardContainer = row
    ? {
        overflow: "auto",
        whiteSpace: "nowrap",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
      }
    : {
        display: "flex",
        flexWrap: "nowrap",
        overflowX: "scroll",
      };

  return (
    <div style={backgroundStyle} ref={ref}>
      <Typography variant="h1">{category}</Typography>
      <Box sx={itemCardContainer}>
        {pasterys.map((pastery, i) => (
          <ItemCard pastery={pastery} key={pastery.id} />
        ))}
      </Box>
      <Divider variant="middle" sx={{ margin: 3 }} />
    </div>
  );
});

export default ItemSection;
