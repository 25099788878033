import * as React from "react";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import ItemSection from "../itemComponents/ItemSection";
import ItemSectionPhone from "../itemComponents/ItemSectionPhone";
import * as helper from "../../utils/Utils";
import { useMediaQuery } from "@mui/material";
import { pasterysFavouritesQuery, pasterysQuery } from "../../utils/Querys";

const Selection = () => {
  const [checked, setChecked] = React.useState(false);
  const [favouritesHeight, setFavouritesHeight] = React.useState(0);
  const phoneView = useMediaQuery("(max-width:600px)");
  const ref = React.useRef(null);
  const pasterysFavouritesQueryVar = pasterysFavouritesQuery();
  const pasterysQueryVar = pasterysQuery();

  React.useEffect(() => {
    if (ref.current) setFavouritesHeight(ref.current.clientHeight);
  });

  if (pasterysQueryVar.isLoading || pasterysFavouritesQueryVar.isLoading) {
    return <div>Loading...</div>;
  }

  if (pasterysQueryVar.isError || pasterysFavouritesQueryVar.isError) {
    return <div>Error fetching data</div>;
  }

  const backgroundStyle = {
    backgroundColor: "#FFEEC3",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  };

  const categorys = helper.listAllCategorysWithPasterys(pasterysQueryVar.data);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <div id="Valikoima">
      {pasterysQueryVar.data && pasterysQueryVar.data.length > 0 ? (
        <div style={backgroundStyle}>
          <Collapse in={checked} collapsedSize={favouritesHeight}>
            {!pasterysFavouritesQueryVar.isLoading && !phoneView && (
              <ItemSection
                category="Suositut"
                pasterys={pasterysFavouritesQueryVar.data}
                ref={ref}
              />
            )}
            {!pasterysFavouritesQueryVar.isLoading && phoneView && (
              <ItemSectionPhone
                category="Suositut"
                pasterys={pasterysFavouritesQueryVar.data}
                ref={ref}
              />
            )}
            {!pasterysQueryVar.isLoading &&
              !phoneView &&
              categorys.map((category) => (
                <ItemSection
                  category={category}
                  key={category}
                  row={true}
                  pasterys={pasterysQueryVar.data.filter(
                    (pastery) => pastery.category.name === category
                  )}
                />
              ))}
            {!pasterysQueryVar.isLoading &&
              phoneView &&
              categorys.map((category) => (
                <ItemSectionPhone
                  category={category}
                  key={category}
                  pasterys={pasterysQueryVar.data.filter(
                    (pastery) => pastery.category.name === category
                  )}
                />
              ))}
          </Collapse>

          <Button
            variant="contained"
            color="secondary"
            sx={{
              justifySelf: "center",
              w: "95%",
              m: "1rem",
              backgroundColor: "wheat",
            }}
            onClick={() => {
              handleChange();
            }}
          >
            {!checked ? "Näytä koko valikoima" : "Piilota valikoima"}
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Selection;
