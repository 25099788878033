import { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

/**
 * This component is to display the select component.
 * This enables customers to switch between sizes of pasterys
 * and manipulates the prices accordingly.
 * @param {*} prices List of possible prices in pastery
 * @param {*} setSelectedPrice useState hook to set items active price
 *
 * @returns Component with full form to change size of pastery
 */
export default function SizeSelector({ prices, setSelectedPrice, style }) {
  /**
   * Changes price based on selected price
   */
  const handleChange = (event) => {
    if (event)
      setSelectedPrice(
        prices.filter((price) => price.price === event.target.value)[0]
      );
    else {
      setSelectedPrice(prices[0]);
    }
  };

  /**
   * Sets default price of pastery
   */
  useEffect(() => {
    handleChange();
  }, []);

  return (
    <FormControl
      size="small"
      fullWidth
      disabled={prices.length <= 1}
      style={style}
    >
      <InputLabel>Koko</InputLabel>
      <Select
        label="price"
        onChange={handleChange}
        defaultValue={prices[0].price}
        onLoad={handleChange}
      >
        {prices.map((price, i) => (
          <MenuItem key={i} value={price.price}>
            {price.size.forThisMany} Hengen
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
