import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import React, { useRef } from "react";
import { cartItemsQuery } from "../../utils/Querys";
import ItemCardPhone from "../itemComponents/ItemCardPhone";
import ShippingCartPastery from "./ShippingCartPastery";

const ShoppingCartItemSection = ({ height, preview }) => {
  const cartItemsQueryVar = cartItemsQuery();
  const tabletView = useMediaQuery("(max-width:850px)");
  const phoneView = useMediaQuery("(max-width:700px)");

  if (cartItemsQueryVar.isLoading) return <div>fetching data</div>;
  return (
    <Box
      sx={{
        maxWidth: "400px",
        width: "100%",
        margin: phoneView ? "auto" : 0,
        height: tabletView ? "auto" : "610px",
        overflow: "scroll",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexShrink: "0",
      }}
    >
      <Typography variant="h2" m={1}>
        Tuotteet
      </Typography>
      {cartItemsQueryVar.data.map((pastery, i) => (
        <ShippingCartPastery
          pastery={pastery}
          key={i}
          index={i}
          disabled={preview}
        />
      ))}
    </Box>
  );
};

export default ShoppingCartItemSection;
