import React from "react";

const Kartta = () => {
  return (
    <iframe
      style={{
        height: 400,
        width: 400,
        borderRadius: 5,
        margin: "2rem",
        border: "0",
        boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.75)",
      }}
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1979.5254875897313!2d24.977906777333427!3d60.254767036603006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4692086acf23ffff%3A0x25e2660674d79441!2sJokitie%2010b%2C%2000780%20Helsinki!5e0!3m2!1sfi!2sfi!4v1705668210574!5m2!1sfi!2sfi"
    ></iframe>
  );
};

export default Kartta;
