import React, { useState } from "react";
import {
  AppBar,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Toolbar,
} from "@mui/material";
import { strings } from "../utils/Localization";
import { useMediaQuery, Modal } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { cartItemsQuery } from "../utils/Querys";
import ShoppingCart from "./shoppingCartComponents/ShoppingCart";

const Header = () => {
  const phoneView = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = useState(false);

  return (
    <Container sx={phoneView ? { padding: 0 } : {}}>
      <AppBar
        position="sticky"
        sx={{ boxShadow: "none", margin: "auto" }}
        color="secondary"
      >
        {!phoneView ? (
          <Toolbar
            sx={
              !phoneView && {
                justifyContent: "space-around",
              }
            }
          >
            {strings.header.map((item, i) => {
              return (
                <Button
                  key={i}
                  variant="text"
                  disableElevation={true}
                  href={"#" + item}
                  sx={{
                    flexGrow: 3,
                    fontFamily: "Indie Flower",
                    fontSize: "24px",
                  }}
                >
                  {item}
                </Button>
              );
            })}
            <Box>
              <ShoppingCart />
            </Box>
          </Toolbar>
        ) : (
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <IconButton onClick={() => setOpen(!open)}>
              <MenuIcon />
            </IconButton>
            <ShoppingCart />
          </Toolbar>
        )}
        <Modal
          open={open}
          onClose={() => setOpen(!open)}
          hideBackdrop
          sx={{
            backgroundColor: "#FFEEC3",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <>
            <IconButton
              sx={{
                position: "absolute",
                top: "0",
                marginLeft: "16px",
                marginTop: "8px",
              }}
              onClick={() => setOpen(!open)}
            >
              <CloseIcon />
            </IconButton>
            {strings.header.map((item, i) => {
              return (
                <Button
                  onClick={() => setOpen(!open)}
                  href={"#" + item}
                  variant="text"
                  key={i}
                  size="large"
                  sx={{ fontFamily: "Indie Flower", fontSize: "30px" }}
                >
                  {item}
                </Button>
              );
            })}
          </>
        </Modal>
      </AppBar>
    </Container>
  );
};

export default Header;
