import { cartHook, converPriceStrToNum, customerInfo } from "./Utils";

/**
 * Fetches pasterys from the API
 * @returns {Promise} List of all pasterys
 */
export const fetchPasterys = async () => {
  const response = await fetch(`${process.env.REACT_APP_LOCAL_IP}/pasterys/`);
  return response.json();
};

/**
 * Fetches news topics from the API
 * @returns {Promise} List of all topics
 */
export const fetchNews = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_LOCAL_IP}/information/`
  );
  return response.json();
};

/**
 * Fetches employee info from the API
 * @returns {Promise} the first employee in the database
 */
export const fetchInfo = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_LOCAL_IP}/information/employee/`
  );
  return response.json();
};

/**
 * Fetches favourites pasterys from the API
 * @returns {Promise} List of all favourites pasterys
 */
export const fetchFavouritesPasterys = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_LOCAL_IP}/pasterys/favourites/`
  );

  return response.json();
};

/**
 * Fetches comapny info from the API
 * @returns {Promise} object with company info
 */
export const fetchCompanyInfo = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_LOCAL_IP}/information/company_info/`
  );
  return response.json();
};

/**
 * Fetches crsf token
 * @returns {Promise} object with company info
 */
export const fetchCSRFToken = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_LOCAL_IP}/get-csrf-token/`,
    { credentials: "include" }
  );
  return response.json();
};

/**
 * Posts order info to the API
 * @returns {Promise} object with company info
 */
export const createOrder = async (order) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_LOCAL_IP}/orders/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(order),
    });
    if (!response.ok) {
      throw new Error();
    }
    return response;
  } catch (e) {
    throw new Error(
      "Tilaus epäonnistui. Avaa sivusto uudelleen ja kokeile uusiksi. Mikäli ongelma jatkuu olethan yhteydessä minuun puhelimitse tai sähköpostitse."
    );
  }
};

export const getCartItems = () => {
  return cartHook;
};

export const getCustomerInfo = () => customerInfo;
