import React, { Component, useEffect, useState } from "react";
import { mainTheme } from "./components/Theme";
import { ThemeProvider } from "@mui/material/styles";
import Header from "./components/Header";
import { Container } from "@mui/material";
import News from "./components/pages/News";
import { strings } from "./utils/Localization";
import Selection from "./components/pages/Selection";
import About from "./components/pages/About";
import ShoppingCart from "./components/shoppingCartComponents/ShoppingCart";
import { crsfTokenQuery } from "./utils/Querys";

function App() {
  //Variables
  const [lang, setLang] = useState("fi"); //Current shown language

  /**
   * Sets starting language
   */
  useEffect(() => {
    strings.setLanguage(lang);
  }, [lang]);

  return (
    <ThemeProvider theme={mainTheme}>
      <Header />
      <Container maxWidth="lg" sx={{ padding: 0 }}>
        <News />
        <Selection />
        <About />
      </Container>
    </ThemeProvider>
  );
}

export default App;
