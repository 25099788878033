import * as React from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import * as helper from "../../utils/Utils";
import { Box } from "@mui/material";
import SizeSelector from "./SizeSelector";
import { addItemToCartMutation } from "../../utils/Querys";

const ItemCard = ({ pastery }) => {
  const [selectedPrice, setSelectedPrice] = React.useState();
  const addToCart = addItemToCartMutation();
  return (
    <Card
      sx={{
        width: 250,
        height: 420,
        margin: "5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        flexShrink: 0,
      }}
    >
      <CardHeader
        title={pastery.name + " "}
        titleTypographyProps={{ fontSize: "1rem" }}
        subheader={
          pastery.allergens.length > 0 &&
          helper.convertAllergensOfPasteryToStringOfAbbrevations(
            pastery.allergens
          )
        }
        sx={{ whiteSpace: "normal", p: 0, m: "1rem" }}
        disableTypography
      />
      <CardMedia
        component="img"
        height="200px"
        src={pastery.image}
        alt={pastery.name}
        sx={{
          objectFit: "cover",
          alignSelf: "center",
          maxWidth: 200,
        }}
      />
      <CardActions sx={{ mt: "auto", display: "block" }}>
        <SizeSelector
          prices={pastery.price_set}
          setSelectedPrice={setSelectedPrice}
        />
        <Typography variant="h5" align="center" style={{ margin: 10 }}>
          {selectedPrice && Number(selectedPrice.price)}€
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          style={{ margin: 0 }}
          onClick={() => {
            pastery.price = selectedPrice;
            addToCart.mutate(pastery);
          }}
        >
          Lisää koriin
        </Button>
      </CardActions>
    </Card>
  );
};

export default ItemCard;
