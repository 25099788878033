import { Box, Button, TextField, Typography } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import dayjs from "dayjs";
import { cartItemsQuery, clearCartItemsQuery } from "../../utils/Querys";
import { parsePasterysForOrder } from "../../utils/Utils";
import { createOrder } from "../../utils/Services";

const OrderForm = ({ setCreatingOrder }) => {
  const inputFields = [
    {
      name: "Etunimi",
      forObj: "firstname",
    },
    {
      name: "Sukunimi",
      forObj: "surname",
    },
    {
      name: "Puhelinnumero",
      forObj: "phone",
    },
    {
      name: "Sähköpostiosoite",
      forObj: "email",
    },
    {
      name: "Nouto päivämäärä",
      forObj: "pickUpDate",
    },
    {
      name: "Nouto kellonaika",
      forObj: "pickUpTime",
    },
  ];

  const cartListQuery = cartItemsQuery();
  const clearCartQuery = clearCartItemsQuery();
  const total = cartListQuery.data
    .map((pastery) => Number(pastery.price.price))
    .reduce((acc, inc) => acc + inc, 0);
  const onSubmit = async (data) => {
    setCreatingOrder(true);
    data.pasterys = parsePasterysForOrder(cartListQuery.data);
    data.total = total.toFixed(2);
    setTimeout(async () => {
      try {
        await createOrder(data);
        setCreatingOrder(false);
        clearCartQuery.mutate();
        alert(
          "Tilauksesi on vastaanotettu. Sähköpostista löydät tilausvahvistuksen. Mikäli tilausvahvistusta ei löydy olethan yhteydessä minuun."
        );
      } catch (e) {
        alert(e.message);
        setCreatingOrder(false);
      }
    }, 1500);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h2" m={1} sx={{ textAlign: "center" }}>
        Yhteystiedot
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
          height: "550px",
        }}
      >
        {inputFields.map((inputField) => {
          if (inputField.name === "Nouto päivämäärä")
            return (
              <Controller
                name={inputField.forObj}
                control={control}
                rules={{ required: `${inputField.name} on pakollinen kenttä.` }}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label={inputField.name + " *"}
                      sx={{
                        m: 1,
                        width: "95%",
                      }}
                      disablePast
                      format="DD.MM.YYYY"
                      desktopModeMediaQuery="Mobile"
                      slotProps={{
                        "!.MuiDialogActions-root .MuiButton-text": {
                          color: "secondary",
                        },
                        textField: {
                          onKeyDown: (e) => {
                            e.preventDefault();
                          },
                          error: !!errors[inputField.forObj],
                          helperText:
                            !!errors[inputField.forObj] &&
                            errors[inputField.forObj].message,
                        },
                      }}
                      onChange={(date) => {
                        field.onChange(dayjs(date.$d).format("DD.MM.YYYY"));
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            );
          if (inputField.name === "Nouto kellonaika")
            return (
              <Controller
                name={inputField.forObj}
                control={control}
                rules={{ required: `${inputField.name} on pakollinen kenttä.` }}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      ampm={false}
                      desktopModeMediaQuery="Mobile"
                      slotProps={{
                        textField: {
                          error: !!errors[inputField.forObj],
                          helperText:
                            !!errors[inputField.forObj] &&
                            errors[inputField.forObj].message,
                        },
                      }}
                      label={inputField.name + " *"}
                      sx={{ m: 1, width: "95%" }}
                      format="H:m"
                      onChange={(date) => {
                        field.onChange(dayjs(date.$d).format("HH:mm"));
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            );
          return (
            <TextField
              {...register(inputField.forObj, {
                required: `${inputField.name} on pakollinen kenttä.`,
                ...(inputField.name === "Sähköpostiosoite" && {
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }),
                ...((inputField.name === "Etunimi" ||
                  inputField.name === "Sukunimi" ||
                  inputField.name === "Puhelinnumero") && {
                  maxLength: {
                    value: 30,
                    message:
                      inputField.name + " ei voi olla pitempi kuin 30 merkkiä",
                  },
                }),
              })}
              label={inputField.name}
              sx={{ m: 1, width: "95%" }}
              name={inputField.forObj}
              error={!!errors[inputField.forObj]}
              helperText={
                !!errors[inputField.forObj] && errors[inputField.forObj].message
              }
            />
          );
        })}{" "}
        <Typography variant="h6" sx={{ textAlign: "center", m: "auto" }}>
          Yhteensä: {total.toFixed(2)} €
        </Typography>
        {/* <Button
          sx={{ m: 1, width: "95%" }}
          variant="contained"
          type="submit"
          color="secondary"
        >
          Vahvista tilaus
        </Button> */}
      </form>
    </Box>
  );
};

export default OrderForm;
