import { Divider, Typography } from "@mui/material";
import * as service from "../../utils/Services";
import React from "react";
import Kartta from "../aboutComponents/Kartta";
import ContactInfo from "../aboutComponents/ContactInfo";
import { informationQuery, aboutQuery } from "../../utils/Querys";

const About = () => {
  const aboutQueryVar = aboutQuery();
  const informationQueryVar = informationQuery();
  const dataIsLoaded = aboutQueryVar.data && informationQueryVar.data;

  if (aboutQueryVar.isLoading || informationQueryVar.isLoading) {
    return <div>Loading...</div>;
  }

  if (aboutQueryVar.isError || informationQueryVar.isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <>
      {dataIsLoaded ? (
        <div
          id="Minusta"
          style={{
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "whitesmoke",
          }}
        >
          <Typography variant="h1" p="2rem">
            Minusta
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <img
              src={`${aboutQueryVar.data.image}`}
              style={{
                borderRadius: "50%",
                height: "50vw",
                width: "50vw",
                maxHeight: "400px",
                maxWidth: "400px",
                minHeight: "250px",
                minWidth: "250px",
                objectFit: "cover",
                boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.75)",
                objectPosition: "top",
                margin: "2rem",
              }}
            />

            <Typography
              sx={{ maxWidth: 400, margin: "2rem", width: "100%" }}
              variant="body2"
            >
              {aboutQueryVar.data.description}
            </Typography>
          </div>
          <Divider style={{ margin: "2rem" }} />
          <Typography variant="h1" sx={{ letterSpacing: 2 }} id="Yhteystiedot">
            Yhteystiedot
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <ContactInfo information={informationQueryVar.data} />
            <Kartta />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default About;
