import {
  Box,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useRef, useState } from "react";
import ShoppingCartButton from "./ShoppingCartButton";
import ShoppingCartItemSection from "./ShoppingCartItemSection";
import { cartItemsQuery } from "../../utils/Querys";
import OrderForm from "./OrderForm";
import CloseIcon from "@mui/icons-material/Close";

const ShoppingCart = () => {
  const [open, setOpen] = useState(false);
  const cartItemsQueryVar = cartItemsQuery();
  const tabletView = useMediaQuery("(max-width:850px)");
  const phoneView = useMediaQuery("(max-width:700px)");
  const [creatingOrder, setCreatingOrder] = useState(false);
  const paperRef = useRef();
  const titleRef = useRef();
  const handleShoppingCart = () => {
    setOpen(!open);
  };

  return (
    <>
      <ShoppingCartButton handleClick={handleShoppingCart} />
      <Drawer
        anchor="top"
        open={open}
        onClose={handleShoppingCart}
        ref={paperRef}
        PaperProps={{
          sx: tabletView
            ? { width: "100%", m: "auto", height: "700px" }
            : { width: "850px", m: "auto", height: "700px" },
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: "0",
            marginLeft: "16px",
            marginTop: "8px",
          }}
          onClick={() => setOpen(!open)}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          ref={titleRef}
          variant="h1"
          sx={{ textAlign: "center", m: 1 }}
        >
          Ostoskori
        </Typography>
        {creatingOrder ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress color="secondary" />
          </Box>
        ) : cartItemsQueryVar.isFetched && cartItemsQueryVar.data.length > 0 ? (
          <>
            <Divider variant="middle" />
            <Box sx={{ display: "flex", flexDirection: phoneView && "column" }}>
              <ShoppingCartItemSection
                height={paperRef.current && paperRef.current.clientHeight}
                titleRef={titleRef.current && titleRef.current.clientHeight}
              />
              <Divider orientation={!phoneView && "vertical"} flexItem />
              <OrderForm
                creatingOrder={creatingOrder}
                setCreatingOrder={setCreatingOrder}
              />
            </Box>
          </>
        ) : (
          <Typography variant="h2" sx={{ textAlign: "center", m: "auto" }}>
            Ostoskori on tyhjä
          </Typography>
        )}
      </Drawer>
    </>
  );
};

export default ShoppingCart;
