import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardHeader } from "@mui/material";
import * as helper from "../../utils/Utils";
import SizeSelector from "./SizeSelector";
import { useState } from "react";
import { addItemToCartMutation } from "../../utils/Querys";

export default function ItemCardPhone({ pastery }) {
  const [selectedPrice, setSelectedPrice] = useState();
  const addToCart = addItemToCartMutation();
  return (
    <Card
      sx={{
        display: "flex",
        maxWidth: "450px",
        m: "4px",
        height: 170,
        width: "95%",
      }}
    >
      <CardMedia
        component="img"
        src={pastery.image}
        sx={{ width: 141, fillObject: "cover" }}
        alt={pastery.name}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          whiteSpace: "nowrap",
        }}
      >
        <CardHeader
          title={pastery.name + " "}
          titleTypographyProps={{ fontSize: 18, textAlign: "center" }}
          subheader={
            pastery.allergens.length > 0 &&
            helper.convertAllergensOfPasteryToStringOfAbbrevations(
              pastery.allergens
            )
          }
          sx={{ whiteSpace: "normal", p: 0, m: "auto" }}
          disableTypography
        />
        <CardContent
          sx={{ display: "flex", justifyContent: "space-around", pt: 0 }}
        >
          <SizeSelector
            prices={pastery.price_set}
            setSelectedPrice={setSelectedPrice}
            style={{ width: "50%" }}
          />
          <Typography sx={{ margin: "auto" }} variant="h6">
            {selectedPrice && Number(selectedPrice.price)}€
          </Typography>
        </CardContent>
        <CardActionArea sx={{ width: "100%", textAlign: "center" }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ mb: 1, width: "95%" }}
            onClick={() => {
              pastery.price = selectedPrice;
              addToCart.mutate(pastery);
            }}
          >
            Lisää koriin
          </Button>
        </CardActionArea>
      </Box>
    </Card>
  );
}
